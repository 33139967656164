.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 300;
  transform: translate(-50%, -50%);
  text-align: center;
  &__blue {
    color: rgb(56, 116, 203);
  }
}
