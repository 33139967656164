$video-min-height: 58vh;
$border_radius: 5px;

.process {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: $video-min-height;
    align-items: center;
    & > h1 {
      align-self: flex-start;
    }
  }
}

.video {
  max-height: 100%;
  min-height: $video-min-height;
  border-radius: $border_radius;
  &__mirrored {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
  &__container {
    position: relative;
    height: 100%;
    display: inline-flex;
    align-self: center;
    flex-grow: 0;
  }
  &__hidden {
    opacity: 0;
  }
}

.canvas {
  position: absolute;
  max-height: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  border-radius: $border_radius;
  &__ROI {
    opacity: 1;
  }
  &__invisible {
    opacity: 0;
  }
}

.tint {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background: rgba($color: #000000, $alpha: 0.7);
}
