.results {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__img {
    opacity: 1;
    border-radius: 5px;
  }
}
